<template>
  <div id="recruit">
    <div class="banner">
      <p>人才招聘</p>
    </div>
    <div class="container">
      <div class="main">
        <div class="m_top">
          <div class="type">
            <span class="t">职位类别:</span>
            <div class="t_right">
              <button
                class="t_item"
                v-for="(type, index) in search.type"
                :key="index"
                :style="{ width: type.width }"
                :class="{ active: type.name === '全部' }"
                @click="selectType($event, type.name)"
              >
                {{ type.name }}
              </button>
            </div>
          </div>
          <div class="address">
            <span class="a">工作地点:</span>
            <div class="a_right">
              <button
                class="a_item"
                v-for="(address, index) in search.address"
                :key="index"
                @click="selectAddress($event, address)"
                :class="{ active: address === '全部' }"
              >
                {{ address }}
              </button>
            </div>
          </div>
        </div>
        <el-table
          class="bottom"
          :data="filterData"
          :header-cell-style="headerCellStyle"
          :cell-style="setCellStyle"
          @row-click="showDetail"
        >
          <el-table-column
            v-for="(item, index) in tableFields"
            :key="index"
            :label="item.label"
            :prop="item.prop"
          >
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { getRecruitData } from "@/request.js";
export default {
  name: "Recruit",
  data() {
    return {
      search: {
        type: [
          {
            name: "全部",
            width: "0.5rem",
          },
          {
            name: "销售",
            width: "0.5rem",
          },
          {
            name: "研发",
            width: "0.5rem",
          },
          {
            name: "技术支持",
            width: "1rem",
          },
          {
            name: "运营",
            width: "0.5rem",
          },
          {
            name: "冷链",
            width: "0.5rem",
          },
          {
            name: "物流",
            width: "0.5rem",
          },
          {
            name: "运输",
            width: "0.5rem",
          },
        ],
        address: [
          "全部",
          "武汉",
          "上海",
          "杭州",
          "北京",
          "重庆",
          "郑州",
          "太原",
          "西安",
          "南京",
          "广州",
          "无锡",
          "贵州",
          "南宁",
          "深圳",
        ],
      },
      searchData: { type: "", address: "" },
      tableFields: [
        {
          label: "职位名称",
          prop: "name",
        },
        {
          label: "职位类别",
          prop: "type",
        },
        {
          label: "工作地点",
          prop: "address",
        },
        {
          label: "发布时间",
          prop: "time",
        },
      ],
      filter: {
        type: "全部",
        address: "全部",
      },
      tableData: [],
      filterData: [],
    };
  },
  computed: {
    headerCellStyle() {
      return {
        background: "#1867DD",
        height: "0.42rem",
        color: "#FFFFFF",
        fontWeight: 400,
        fontSize: "0.12rem",
      };
    },
  },
  methods: {
    //设置单元格样式
    setCellStyle({ column }) {
      if (column.property === "name") {
        return {
          padding: "0.11rem 0",
          fontSize: "0.12rem",
          fontWeight: 400,
          color: "#528DE5",
        };
      } else {
        return {
          padding: "0.2rem 0",
          fontSize: "0.12rem",
          fontWeight: 400,
          color: "#606266",
        };
      }
    },
    //选择职位类
    selectType(e, type) {
      //样式改变
      this.setClass(e, "t_item");
      //筛选
      this.filter.type = type;
      let address = this.filter.address;
      this.getData(type, address);
    },

    //选择职位地址
    selectAddress(e, address) {
      this.setClass(e, "a_item");
      //筛选
      this.filter.address = address;
      let type = this.filter.type;
      this.getData(type, address);
    },

    setClass(e, className) {
      let btns = [...document.getElementsByClassName(className)];
      btns.forEach((btn) => (btn.className = className));
      let btn = e.target;
      btn.className = `${className} active`;
    },

    getData(type, address) {
      if (type === "全部" && address === "全部") {
        this.filterData = this.tableData;
      } else if (type !== "全部" && address !== "全部") {
        this.filterData = this.tableData.filter(
          (data) => data.type === type && data.address === address
        );
      } else if (type === "全部" && address !== "全部") {
        this.filterData = this.tableData.filter(
          (data) => data.address === address
        );
      } else {
        this.filterData = this.tableData.filter((data) => data.type === type);
      }
    },

    //显示职位详情
    showDetail(row, column) {
      this.$router.push({
        path: "/recruitDetail",
        query: {
          row,
        },
      });
    },
  },
  mounted() {
    this.tableData = getRecruitData();
    this.filterData = getRecruitData();
  },
};
</script>
<style lang='scss' scoped>
#recruit {
  width: 100%;
  background: #ffffff;
  font-family: Microsoft YaHei;
  .banner {
    position: relative;
    height: 3.655rem;
    background: url("../assets/image/recruit/b_1.png") no-repeat;
    background-size: cover;
    p:nth-child(1) {
      position: absolute;
      top: 0.92rem;
      left: 5.92rem;
      font-size: 0.36rem;
      font-weight: bold;
      color: #fffefe;
    }
  }
  .container {
    position: relative;
    height: 11.495rem;
    .main {
      position: absolute;
      left: 3.22rem;
      bottom: 0.92rem;
      width: 11.79rem;
      height: 10.99rem;
      z-index: 2;
      .m_top {
        width: 100%;
        height: 1.62rem;
        padding: 0.42rem 0 0 0.38rem;
        box-shadow: 0rem 0rem 0.24rem 0rem rgba(186, 186, 186, 0.44);
        background: #ffffff;
        .type,
        .address {
          .t,
          .a {
            height: 0.14rem;
            font-size: 0.14rem;
            font-weight: 400;
            color: #303133;
            line-height: 0.24rem;
          }
          .t_right,
          .a_right {
            display: inline;
            margin-left: 0.25rem;
            .t_item,
            .a_item {
              width: 0.5rem;
              height: 0.3rem;
              border: none;
              border-radius: 0.02rem;
              margin-right: 0.17rem;
              font-size: 0.14rem;
              font-weight: 400;
              color: #666666;
              line-height: 0.24rem;
              cursor: pointer;
            }
          }
        }
        .address {
          margin-top: 0.18rem;
        }
      }
      .bottom {
        width: 100%;
        height: 8.8rem;
        border-collapse: collapse;
        margin-top: 0.24rem;
        background: #ffffff;
        box-shadow: 0rem 0rem 24rem 0rem rgba(186, 186, 186, 0.44);
      }
    }
  }
}
.active {
  border: 0.01rem solid #1867dd !important;
  color: #1867dd !important;
}
</style>
<style lang="scss">
#recruit {
  .el-table .cell {
    padding-left: 0.38rem !important;
  }
}
</style>